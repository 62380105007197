.ProgressBar {
	width: 100%;
	background-color: rgb(229, 229, 229);
	border-radius: 5px;
	padding: 0.3em;
}

.ProgressBar.Error {
	color: yellow;
	background-color: rgb(200, 80, 80);
	padding: 0.5em;
}

.Progress {
	background-color: rgb(103, 183, 58);
	height: 2rem;
	margin: 0;
	border-radius: 5px;
}